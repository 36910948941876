import {mapActions, mapGetters, mapMutations} from "vuex";
import product from '@/components/product/index.vue'
import _ from "lodash";
export default {
  name: "search-catalog",
  components:{
    product
  },
  data() {
    return {
      querySearch: ''
    }
  },
  created() {
    this.querySearch = this.$route.query.search
    this.fetchSearchItems(this.querySearch).then(()=>{
      this.takeProducts()
    })
  },
  computed: {
    ...mapGetters({
      searchList: 'search/searchList',
      searchLoading: 'search/searchLoading',
      list: 'products/list',
    })
  },
  methods: {
    ...mapActions({
      fetchSearchItems: 'search/GET_SEARCH_LIST',
      fetchProducts: 'products/GET_LIST',
    }),
    ...mapMutations({
      changeInstagramPopup: 'popups/CHANGE_INSTAGRAM_POPUP',
    }),
    sendRequest: _.debounce(function () {
      if (this.querySearch.length > 2) {
        this.fetchSearchItems(this.querySearch).then(()=>{
          this.takeProducts()
        })
      }
    }, 500),
    changeSearch(){
      this.sendRequest()
    },
    takeProducts(){
      let productIds = []
      for(let i in this.searchList.products){
        productIds.push(this.searchList.products[i].id)
      }
      this.fetchProducts({ids:productIds})
    }
  }
}